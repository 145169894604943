import React from "react";
import ReactDynamicImport from 'react-dynamic-import';

// Components Import
import { detectMob } from "@src/Helper";

const loader = () => {
  if (!detectMob()) {
    return (
      import('@components/SocialAccount/SocialAccount')
    )
  } else {
    return (
      import('@components/SocialAccount/SocialAccountMobile')
    )
  }
}

const SocialAccount = ReactDynamicImport({ loader })

const SocialAccountPage = () => <SocialAccount />

export default SocialAccountPage;
